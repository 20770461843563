import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ShipChainLogo from '../static/img/Logo';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { useDisconnect } from '@starknet-react/core';


const Navigation = ({setSessionKey}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const { disconnect } = useDisconnect()

  const logout = ()=>{
    disconnect()
    setSessionKey(undefined)
  }

  return (
    <header className="bg-background border-b border-borderGray shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <ShipChainLogo/>
          </Link>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-8 items-center">
              <Link
                to="/"
                className={`text-textPrimary font-medium ${
                  location.pathname === '/' ? 'text-primary' : 'hover:text-primary'
                }`}
              >
                Shippings
              </Link>
              <Link
                to="/new-shipping"
                className="bg-primary text-textOnPrimary font-semibold py-2 px-4 rounded-md hover:bg-primaryDark"
              >
                New Shipping
              </Link>
              <button
                onClick={logout}
                className="bg-accent text-white font-semibold py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
              >
                Logout
              </button>
            </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
          <button onClick={toggleMenu} className="text-textPrimary focus:outline-none">
            {menuOpen ? (
                <XMarkIcon className="w-6 h-6" />
            ) : (
                <Bars3Icon className="w-6 h-6" />
            )}
            </button>

          </div>
        </div>
      </div>

      {/* Mobile Navigation Links */}
      {menuOpen && (
        <div className="md:hidden border-t border-borderGray">
          <div className="px-4 pt-2 pb-4 space-y-2">
            <Link
              to="/"
              className={`block text-textPrimary font-medium ${
                location.pathname === '/' ? 'text-primary' : 'hover:text-primary'
              }`}
              onClick={() => setMenuOpen(false)}
            >
              Shippings
            </Link>
            <Link
              to="/new-shipping"
              className="block bg-primary text-textOnPrimary font-semibold py-2 px-4 rounded-md text-center hover:bg-primaryDark"
              onClick={() => setMenuOpen(false)}
            >
              New Shipping
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navigation;
