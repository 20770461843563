export function decodeFelt252(felt) {
    let hex = felt.toString(16);
    hex = hex.length % 2 ? '0' + hex : hex;
    return hex.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
}

export function intToU256Struct(integer){
  const bigIntValue = BigInt(integer); // Replace "your_integer_here" with the actual integer
  const MASK_128 = BigInt("0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
  const low = bigIntValue & MASK_128;         // Get the lower 128 bits
  const high = bigIntValue >> BigInt(128);    // Shift right by 128 bits for the upper 128 bits
  const u256 = {
      low: low.toString(),    // Convert to string if you need it in a readable format
      high: high.toString()
  };

  return u256;
}

export function convertContractAddress(address) {
    return "0x" + address.toString(16).padStart(40, '0'); // Ensure 40 characters for address format
}

export function feltToString(feltValue) {
    try {
      let hex = feltValue.startsWith('0x') ? feltValue.slice(2) : feltValue;
      if (hex.length % 2 !== 0) hex = '0' + hex;
      let str = '';
      for (let i = 0; i < hex.length; i += 2) {
        str += String.fromCharCode(parseInt(hex.substring(i, i + 2), 16));
      }
      return str;
    } catch (error) {
      console.error("Error in feltToString:", error);
      return "";
    }
  }

export function u256toInt(highHex, lowHex) {
  // Remove '0x' prefix and ensure strings are properly formatted for BigInt
  const high = BigInt('0x' + highHex.replace(/^0x/, ''));
  const low = BigInt('0x' + lowHex.replace(/^0x/, ''));
  
  // Shift the high part by 128 bits and add the low part
  return (high << 128n) + low;
}


export function generateShortUUID() {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uuid = "";
  for (let i = 0; i < 13; i++) {
    uuid += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return uuid;
}