// src/pages/ShipmentsPage.js

import React from 'react';
import ShipmentsTable from '../components/ShipmentsTable';


const ShipmentsPage = () => (
  <div className="min-h-screen bg-background p-6">
    <ShipmentsTable />
  </div>
);

export default ShipmentsPage;
