let devAbi;
let devDeploymentData;
let prodAbi;
let prodDeploymentData;


if (process.env.REACT_APP_ABI_PATH === "dev") {
  try {
    devAbi = require("../artifacts/dev/abi.json");
    devDeploymentData = require("../artifacts/dev/deploymentData.json")
  } catch (error) {
    console.warn("Dev ABI file not found, proceeding with prod ABI.");
    devAbi = {};
    devDeploymentData= {}
  }
}

prodAbi = require("../artifacts/prod/abi.json");
prodDeploymentData = require('../artifacts/prod/deploymentData.json')

export const abi = process.env.REACT_APP_ABI_PATH === "prod" ? prodAbi : devAbi;
export const deploymentData = process.env.REACT_APP_ABI_PATH === "prod" ? prodDeploymentData : devDeploymentData;
