import { Timeline, Card, Button } from "flowbite-react";
import { HiOutlineExternalLink } from 'react-icons/hi';

const TrackingEvents = ({ events }) => {

  return (
      <Card className="w-full bg-cardBg p-6 rounded-md shadow-card border border-borderGray">
      <h2 className="text-2xl font-semibold text-textPrimary mb-4">Tracking Events</h2>
      <Timeline>
        {events.map((event, index) => (
          <Timeline.Item key={index} className="mb-4">
            <Timeline.Point className="bg-primary" />
            <Timeline.Content>
              <Timeline.Title className="text-lg font-semibold text-textPrimary">{event.description}</Timeline.Title>
              <p className="text-xs text-textSecondary">
                {new Date(event.timestamp).toLocaleString()}
              </p>
              <Button
                href={event.blockLink}
                target="_blank"
                rel="noopener noreferrer"
                color="dark"
                size="sm"
                className="mt-2 flex items-center space-x-2 bg-primary hover:bg-primaryDark shadow-button rounded-md"
              >
                <HiOutlineExternalLink className="w-5 h-5" />
                <span>View Event Block</span>
              </Button>
            </Timeline.Content>
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  )
}

  
  export default TrackingEvents;
