const AddTrackingEventModal = ({ isOpen, onClose, onAdd, newEvent, setNewEvent, isAddingEvent }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-cardBg rounded-lg shadow-lg w-full max-w-md p-6 text-textPrimary min-h-[300px]">
          <h2 className="text-xl font-semibold mb-4 text-primary">Add Tracking Event</h2>
          {
            isAddingEvent ?
            <div className="space-y-4 flex flex-col items-center justify-center min-h-[300px]">
              <div className="w-8 h-8 border-4 border-t-transparent border-primary rounded-full animate-spin"></div>
            </div>
            :
            <>
            <div className="space-y-4">
            <div>
              <label htmlFor="description" className="block font-semibold mb-2">
                Event Description
              </label>
              <input
                id="description"
                type="text"
                value={newEvent.description}
                onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                className="w-full bg-background text-textPrimary border border-borderGray rounded-md p-2 placeholder-textSecondary focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                placeholder="Enter event description"
              />
            </div>
            <div>
              <label htmlFor="details" className="block font-semibold mb-2">
                Additional Details (optional)
              </label>
              <textarea
                id="details"
                value={newEvent.details || ''}
                onChange={(e) => setNewEvent({ ...newEvent, details: e.target.value })}
                className="w-full bg-background text-textPrimary border border-borderGray rounded-md p-2 h-24 placeholder-textSecondary focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                placeholder="Provide additional details if needed"
              />
            </div>
          </div>
          
          <div className="mt-6 flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none"
            >
              Cancel
            </button>
            <button
              onClick={onAdd}
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primaryDark focus:outline-none shadow-button"
            >
              Add Event
            </button>
          </div>
            
            </>
          }
        </div>
      </div>
    );
  };
  
export default AddTrackingEventModal;
  