import React, { useState, useRef } from 'react';

function Recorder() {
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState({size: null});
    const mediaRecorderRef = useRef(null);
    const chunks = useRef([]);

    // Start recording
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => chunks.current.push(event.data);
            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(chunks.current, { type: 'audio/wav' });
                setAudioBlob(blob);
                chunks.current = [];
            };
            mediaRecorderRef.current.start();
            setRecording(true);
        } catch (err) {
            console.error("Error accessing microphone", err);
        }
    };

    // Stop recording
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };

    // Send audio to backend
    const sendAudioToBackend = async () => {
        if (audioBlob) {
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');

            try {
                console.log(audioBlob);
                // const response = await fetch('YOUR_BACKEND_API_URL', {
                //     method: 'POST',
                //     body: formData
                // });
                // const data = await response.json();
                // console.log("Response from backend:", data);
            } catch (err) {
                console.error("Error sending audio to backend", err);
            }
        }
    };

    return (
        <div>
            <button onClick={recording ? stopRecording : startRecording}>
                {recording ? 'Stop Recording' : 'Start Recording'}
            </button>
            {audioBlob && <button onClick={sendAudioToBackend}>Send to Backend</button>}
            {audioBlob.size ? audioBlob.size : null}
        </div>
    );
}

export default Recorder;