import { Timeline, Card } from "flowbite-react";
import { FaTruck, FaCheckCircle, FaCubes } from "react-icons/fa";

const ShippingProgressTimeline = ({ status }) => {

  const steps = [
    { label: "PENDING", icon: <FaCubes />, description: "Awaiting shipment preparation" },
    { label: "IN_TRANSIT", icon: <FaTruck />, description: "Shipment is on the way" },
    { label: "COMPLETED", icon: <FaCheckCircle />, description: "Shipment delivered" },
  ];

  return (
    <Card className="w-full bg-cardBg p-6 rounded-lg shadow-card border border-borderGray">
      <h2 className="text-2xl font-semibold text-primary mb-4">Shipping Progress</h2>
      <p className="text-sm text-textSecondary mb-6">
        Tracking the journey of your shipment through our blockchain-enabled logistics platform.
      </p>
      <Timeline horizontal className="w-full">
        {steps.map((step, index) => {
          const isCompleted = index <= steps.findIndex(s => s.label === status);
          return (
            <Timeline.Item key={index}>
              <Timeline.Point/>
              <Timeline.Content>
                  <Timeline.Title className="text-lg font-semibold text-textPrimary">
                    <div className="flex align-items">
                        <span className={`me-4 mt-1 ${isCompleted ? 'text-success': 'text-gray'}`}>{step.icon}</span>
                        {step.label} 
                    </div> 
                  </Timeline.Title>
                  <Timeline.Body>
                  <span
                    className={`px-2 py-0.5 text-xs rounded-full font-medium ${
                      isCompleted ? 'bg-success text-white' : 'bg-borderGray text-textSecondary'
                    }`}
                  >
                    {isCompleted ? "Completed" : "Upcoming"}
                  </span>
                    <p className="text-sm text-textSecondary mt-2">{step.description}</p>
                  </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Card>
  );
};

export default ShippingProgressTimeline;
