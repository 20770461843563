import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { mainnet } from "@starknet-react/chains";
import { StarknetConfig, InjectedConnector, jsonRpcProvider, publicProvider } from '@starknet-react/core';
import bravoosLogo from "./static/img/braavos.png";

import { deploymentData } from './artifacts';


const root = ReactDOM.createRoot(document.getElementById('root'));

const connectors = [
  new InjectedConnector({options: {id: "braavos", icon: bravoosLogo}}),
  new InjectedConnector({options: {id: "argentX"}})
]

function rpc(chain) {
  return {
    nodeUrl: deploymentData.nodeUrl
  }
}

root.render(
  <React.StrictMode>
        <StarknetConfig
          chains={[mainnet]}
          connectors={connectors}
          provider={jsonRpcProvider({rpc})}
        >
      <App />
    </StarknetConfig>
  </React.StrictMode>
);

