// src/assets/ShipChainLogo.js

import React from 'react';

const ShipChainLogo = () => (
  <svg width="220" height="60" viewBox="0 0 220 60" xmlns="http://www.w3.org/2000/svg">


    {/* Chain Link Icon (Stylized for blockchain) */}
    <path d="M80 20 h10 v20 h-10 a10 10 0 0 1 -10 -10 a10 10 0 0 1 10 -10" fill="#B45309" /> {/* Darker link */}
    <path d="M70 20 h-10 v20 h10 a10 10 0 0 0 10 -10 a10 10 0 0 0 -10 -10" fill="#8C1D1D" /> {/* Dark muted red */}

    {/* ShipChain Text */}
    <text x="100" y="40" fontSize="24" fill="#F5F7FA" fontWeight="bold" fontFamily="Arial, sans-serif">
      ShipChain
    </text>
  </svg>
);

export default ShipChainLogo;
