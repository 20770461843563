import { Button, Card } from "flowbite-react";
import { HiOutlineExternalLink } from 'react-icons/hi';


const ShippingInfoCard = ({ shipping }) => {

  return(
      <Card className="w-full bg-cardBg p-6 rounded-md shadow-card border border-borderGray">
        <h2 className="text-2xl font-semibold text-textPrimary mb-4">Shipping Information</h2>
        <div className="space-y-2 text-textSecondary">
          <p><strong>Container:</strong> {shipping.container_name}</p>
          <p><strong>Origin:</strong> {shipping.origin}</p>
          <p><strong>Shipper:</strong> {shipping.shipper}</p>
          <p><strong>Destination:</strong> {shipping.destination}</p>
          <p><strong>Date Created:</strong> {shipping.date_created ? shipping.date_created.toLocaleString() : null}</p>
        </div>
          <Button
            href={shipping.blockLink}
            target="_blank"
            rel="noopener noreferrer"
            color="dark"
            size="sm"
            className="mt-4 flex items-center space-x-2 bg-primary hover:bg-primaryDark shadow-button rounded-md"
          >
          <HiOutlineExternalLink className="w-5 h-5" />
          <span>View Container Block</span>
        </Button>
      </Card>
    );
}

export default ShippingInfoCard;
