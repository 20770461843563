import { decodeFelt252, convertContractAddress, feltToString, u256toInt} from "./utils"


function parseStatus(statusEnum) {
    const variant = statusEnum.variant;
    if (variant.COMPLETED !== undefined) return "COMPLETED";
    if (variant.IN_TRANSIT !== undefined) return "IN_TRANSIT";
    if (variant.PENDING !== undefined) return "PENDING";
    return "UNKNOWN";
}


export function parseShippingData(shipping) {
    return {
        container_id: BigInt(shipping.container_id),
        container_name: decodeFelt252(shipping.container_name),
        date_created: new Date(Number(shipping.date_created) * 1000),
        destination: decodeFelt252(shipping.destination),
        id: BigInt(shipping.id),
        last_updated: new Date(Number(shipping.last_updated) * 1000),
        origin: decodeFelt252(shipping.origin),
        receiver: convertContractAddress(shipping.receiver),
        shipper: decodeFelt252(shipping.shipper),
        status: parseStatus(shipping.status)
    };
}
  
export function parseShippingCreatedEvent(event) {
    const id = u256toInt(event.keys[2], event.keys[1])
    const hexString = event.data[0].replace(/^0x/, '')
    const timestampInSeconds = parseInt(hexString, 16);
    const timestampMillis = timestampInSeconds * 1000;
    const dateCreated = new Date(timestampMillis);
    return {
        id: id,
        from_address: event.from_address,
        dateCreated: dateCreated,
        block_hash: event.block_hash,
        block_number: event.block_number,
        transaction_hash: event.transaction_hash,
    };
}


export function parseShippingEvent(event, deploymentData) {
    const idLow = BigInt(event.keys[1].replace(/^0x/, ''));
    const idHigh = BigInt(event.keys[2].replace(/^0x/, ''));
    const id = (idHigh << 128n) + idLow;
    const eventDiscrption = feltToString(event.data[0]);

    const hexString = event.data[1].replace(/^0x/, '')
    const timestampInSeconds = parseInt(hexString, 16);
    const timestampMillis = timestampInSeconds * 1000;
    const dateCreated = new Date(timestampMillis);
    return {
        shipping_id: id,
        from_address: event.from_address,
        description: eventDiscrption,
        timestamp: dateCreated,
        block_hash: event.block_hash,
        blockLink: `${deploymentData.blockExplorerUrl}/blocks/${event.block_number}`,
        transaction_hash: event.transaction_hash,
    };
}