import React, { useState } from 'react';
import NewContainer from '../components/NewContainer';
import { useNavigate } from 'react-router-dom';
import { shipperOracle } from '../../ref-oracles/shipper';

import useMultiModalContract from '../../hooks/useMultiModalContract';
import { generateShortUUID } from '../../utils/utils';
import Recorder from './VoiceRecording';

const containers = [
  {"id": 1, name: "CONT192019"},
  {"id": 2, name: "CONT192811"},
  {"id": 3, name: "CONT201029"},
  {"id": 4, name: "CONT499202"}
]

export const NewShippingPage = () => {
  const [shipping, setShipping ] = useState({})
  const [container, setContainer] = useState(containers[0].name);
  const [shipper, setShipper] = useState('');
  const [receiver, setReceiver] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { createShipping } = useMultiModalContract()

  const shippers = shipperOracle.getAllFromOracle();

  const handleShippingChange = (data) => setShipping(data);
  const name = generateShortUUID();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try{
      const createdShippingId = await createShipping(
        container,
        shipping.contents, 
        shipper, 
        receiver, 
        shipping.departureLocation, 
        shipping.arrivalLocation
      )
      navigate('/shippings/' + createdShippingId);
    } catch (e){
      console.log(e)
    }finally{
      setIsSubmitting(false)
    }
  };

  return (
    <>
    <div className="bg-background min-h-screen p-6 flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-cardBg border border-borderGray p-8 rounded-lg shadow-md w-full max-w-container-lg space-y-6"
      >
        <h1 className="text-2xl font-semibold text-primary mb-4">Ship a Container</h1>

        {/* Integrated Container Form */}
        <NewContainer onChange={handleShippingChange} name={name} />

        {/* Shipper Selection */}
        <div>
          <label htmlFor="shipper" className="block text-textPrimary mb-1">Select Shipper</label>
          <select
            id="shipper"
            value={shipper}
            onChange={(e) => setShipper(e.target.value)}
            required
            className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
          >
            <option value="" disabled>Select a shipper</option>
            {shippers.map((s) => (
              <option key={s.id} value={s.name} className="text-textPrimary bg-cardBg">
                {s.name}
              </option>
            ))}
          </select>
        </div>

        {/* Shipper Container */}
        <div>
          <label htmlFor="container" className="block text-textPrimary mb-1">Select Container</label>
          <select
            id="container"
            value={container}
            onChange={(e) => setContainer(e.target.value)}
            required
            className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
          >
            <option value="" disabled>Select a container</option>
            {containers.map((cont) => (
              <option key={cont.id} value={cont.name} className="text-textPrimary bg-cardBg">
                {cont.name}
              </option>
            ))}
          </select>
        </div>

        {/* Receiver Address */}
        <div>
          <label htmlFor="receiver" className="block text-textPrimary mb-1">Receiver Address</label>
          <input
            id="receiver"
            type="text"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
            placeholder="Enter receiver's public address"
            required
            className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={!container || !shipper || !receiver || isSubmitting}
          className={`w-full py-3 bg-primary text-white font-semibold rounded-md shadow-lg hover:bg-primaryDark focus:outline-none ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Ship Container'}
        </button>

      </form>
      
    </div>
    {/* <Recorder/> */}
    </>
  );
};

export default NewShippingPage;
