import { CryptoConnect } from "./CryptoConnect"
import { ec, RpcProvider, Contract} from 'starknet';
import { abi, deploymentData } from "../artifacts";
import ShipChainLogo from "../static/img/Logo";
import { truncateHash } from "../shipping/services/formatter";

export const Login = ({setSessionKey, account, address})=>{

    return (
        <div>
            {
                account == undefined ?
                <CryptoConnect/>
                    :
                <SetSessionKey account={account} setSessionKey={setSessionKey} address={address}/>
            }
        </div>
    )

}


const SetSessionKey = ({account, setSessionKey, address})=>{
    const setSession = async () => {
        try {
            let provider = new RpcProvider({ nodeUrl: deploymentData.nodeUrl });
            let contract = new Contract(abi, deploymentData.contractAddress, provider);
            const expirationTimestamp = Math.floor(Date.now() / 1000) + 3600; // 1-hour expiration
            const sessionPrivateKeyArray = ec.starkCurve.utils.randomPrivateKey();
            const sessionPrivateKey = '0x' + Array.from(sessionPrivateKeyArray)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
            const sessionPublicKey = ec.starkCurve.getStarkKey(sessionPrivateKey)

            localStorage.setItem("session_private_key", sessionPrivateKey);

            contract.connect(account)
            const response = await account.execute({
            contractAddress: contract.address,
            entrypoint: "set_session_key",
            calldata: [sessionPublicKey, expirationTimestamp],
            });
            setSessionKey(sessionPublicKey);
            // setExpiration(expirationTimestamp);
        }catch (e){
            console.error("Error setting session key: ", e)
        }
    };
    
    return (
            <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex flex-col items-center justify-center px-4">
                <div className="bg-cardBg rounded-2xl shadow-lg transform transition-all duration-300 p-8 w-full max-w-md border border-gray-700 text-center">
                    <div className="mb-6 flex justify-center">
                        <ShipChainLogo className="h-14 w-14" />
                    </div>
                    <div className="text-gray-400 font-medium text-xs tracking-wide mb-8">
                        Connected: <span className="text-gray-200 font-semibold">{truncateHash(address)}</span>
                    </div>
                    <button 
                        onClick={setSession} 
                        className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-semibold py-3 px-6 rounded-full w-full transition-transform duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                    >
                        Log In
                    </button>
                </div>
            </div>
    )

}