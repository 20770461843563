export class ShipperOracle{

    getAllFromOracle(){
        return [
            { id: '1', name: 'Maersk Line' },
            { id: '2', name: 'Mediterranean Shipping Company (MSC)' },
            { id: '3', name: 'COSCO Shipping' },
            { id: '4', name: 'CMA CGM' },
            { id: '5', name: 'Evergreen Marine' },
            { id: '6', name: 'Hapag-Lloyd' },
            { id: '7', name: 'Ocean Network Express (ONE)' },
            { id: '8', name: 'Yang Ming Marine Transport Corporation' },
            { id: '9', name: 'Hyundai Merchant Marine (HMM)' },
            { id: '10', name: 'ZIM Integrated Shipping Services' },
            { id: '11', name: 'Orient Overseas Container Line (OOCL)' },
            { id: '12', name: 'Pacific International Lines (PIL)' },
            { id: '13', name: 'Wan Hai Lines' },
            { id: '14', name: 'Kawasaki Kisen Kaisha, Ltd. ("K" Line)' }
          ];
    }
}

export const shipperOracle = new ShipperOracle()