const ReceiveShippingButton = ({ isReceiver, validateReceipt, status, isValidating }) => (
  <div className="relative group inline-block">
    <button
      disabled={!isReceiver || status === "COMPLETED" || isValidating}
      className={`bg-accent text-white px-4 py-2 rounded-md shadow-button hover:bg-primaryDark focus:outline-none ${
        (!isReceiver || status === "COMPLETED") && 'opacity-50 cursor-not-allowed'
      }`}
      onClick={validateReceipt}
    >
      {isValidating ? (
        <div className="flex items-center">
          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
          Validating...
        </div>
      ) : (
        "Validate Receipt"
      )}
    </button>

    {/* Tooltip */}
    <div
      className="absolute bottom-full mb-2 hidden group-hover:flex w-max px-3 py-1 bg-gray-800 text-textPrimary text-sm rounded-md shadow-lg"
      style={{ transform: 'translateX(-50%)', left: '50%' }}
    >
      {status === "COMPLETED"
        ? "Shipping already completed"
        : "Only the receiver can validate this shipping according to Smart Contract rules"}
    </div>
  </div>
);

export default ReceiveShippingButton;
