import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { truncateHash } from '../services/formatter';
import { Button } from 'flowbite-react';
import ReceiveShippingButton from '../components/ReceiveShippingButton';
import ShippingInfoCard from '../components/ShippingInfoCard';
import ShippingProgressTimeline from '../components/ShippingProgressTimeline';
import TrackingEvents from '../components/TrackingEvents';
import AddTrackingEventModal from '../components/AddTrackingModal';
import { ClipboardDocumentIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

import useMultiModalContract from '../../hooks/useMultiModalContract';


const ShippingDetail = () => {
  const { id } = useParams();
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [ isAddingEvent, setIsAddingEvent ] = useState(false)
  const [ isValidating, setIsValidating ] = useState(false)
  const [newEvent, setNewEvent] = useState({ description: '', timestamp: new Date().toISOString() });
  const [copiedHash, setCopiedHash] = useState(null);
  const { shipping, createShippingEvent, validateReceipt, isLoading } = useMultiModalContract({detail: true, shippingId: id})
  
  if (isLoading) return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className="w-8 h-8 border-4 border-t-transparent border-primary rounded-full animate-spin"></div>
    </div>
  )
  if (!shipping.id) return <p className="text-error text-lg font-semibold">Shipping not found.</p>;

  const handleAddEvent = async () => {
    setIsAddingEvent(true)
    try {
      await createShippingEvent(newEvent.description)
      setModalOpen(false)
    } catch (e){
      console.log(e)
    }finally{
      setIsAddingEvent(false)
    }
  };

  const handleValidateReceipt = async () => {
    setIsValidating(true)
    try {
      await validateReceipt()
      setModalOpen(false)
    } catch (e){
      console.log(e)
    }finally{
      setIsValidating(false)
    }
  }

  const handleCopyHash = () => {
    navigator.clipboard.writeText(shipping.id);
    setCopiedHash(shipping.id);
    setTimeout(() => setCopiedHash(null), 1500);
  };

  return (
    <div className="bg-background min-h-screen p-8 flex flex-col items-center">
      <div className="max-w-container-lg w-full relative space-y-8">
        <div className="float-right top-0 right-0 space-x-3 flex">
          <Button 
            onClick={() => setModalOpen(true)} 
            color="primary" 
            className="rounded-md shadow-button bg-primary hover:bg-primaryDark text-white"
            disabled={shipping.status == "COMPLETED"}
          >
            Add Tracking Event
          </Button>
          <ReceiveShippingButton 
            isReceiver={true} 
            validateReceipt={handleValidateReceipt}
            status={shipping.status} 
            isValidating={isValidating} 
          />
        </div>
        <h1 className="text-3xl font-extrabold text-primary text-center mb-6 flex items-center space-x-2">
          <button
            onClick={handleCopyHash}
            title="Copy full hash"
            className="text-primary hover:text-primaryDark focus:outline-none p-1"
          >
            {copiedHash === shipping.id ? (
              <CheckCircleIcon className="w-5 h-5 text-green-500" />
            ) : (
              <ClipboardDocumentIcon className="w-5 h-5" />
            )}
          </button>
          <span>Shipping Detail - {shipping.transaction_hash ? truncateHash(shipping.transaction_hash) : null}</span>
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <ShippingInfoCard shipping={shipping} />
          <TrackingEvents events={shipping.events} />
        </div>
        <ShippingProgressTimeline status={shipping.status} />
        <AddTrackingEventModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onAdd={handleAddEvent}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          isAddingEvent={isAddingEvent}
        />
      </div>
    </div>
  );
};

export default ShippingDetail;
