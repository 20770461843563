import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipboardDocumentIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { truncateHash } from '../services/formatter';
import { Table, Badge, Tooltip } from 'flowbite-react';

import useMultiModalContract from '../../hooks/useMultiModalContract';

const ShipmentsTable = () => {


  const { shippings, fetchShippingsError, isLoading } = useMultiModalContract({fetchShippings: true})

  return (
<div className="bg-cardBg p-6 rounded-lg shadow-card max-w-4xl mx-auto overflow-x-auto border border-borderGray min-h-[700px]">
  <h2 className="text-2xl font-semibold text-primary mb-4">Deployed Shippings</h2>
  <Table hoverable={true} className="rounded-lg bg-cardBg">
    <Table.Head>
      <Table.HeadCell className="text-textSecondary bg-cardBg">Shipping Hash</Table.HeadCell>
      <Table.HeadCell className="text-textSecondary bg-cardBg">Status</Table.HeadCell>
      <Table.HeadCell className="text-textSecondary bg-cardBg">Timestamp</Table.HeadCell>
      <Table.HeadCell className="text-textSecondary bg-cardBg">Block Explorer</Table.HeadCell>
      <Table.HeadCell className="text-textSecondary bg-cardBg">Details</Table.HeadCell>
    </Table.Head>
    <Table.Body className="divide-y bg-cardBg">
      {
        !isLoading ? (
          <TableData shippings={shippings} fetchShippingsError={fetchShippingsError} />
        ) : (
          <Table.Row>
            <Table.Cell colSpan="5" className="py-10">
              <div className="flex justify-center items-center">
                <div className="w-8 h-8 border-4 border-t-transparent border-primary rounded-full animate-spin"></div>
              </div>
            </Table.Cell>
          </Table.Row>
        )
      }
    </Table.Body>
  </Table>
</div>


  );
};



const TableData = ({shippings, fetchShippingsError})=>{
  const [copiedHash, setCopiedHash] = useState(null);
  
  const handleCopyHash = (hash) => {
    navigator.clipboard.writeText(hash);
    setCopiedHash(hash);
    setTimeout(() => setCopiedHash(null), 1500);
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'PENDING':
        return <Badge color="yellow" className="bg-yellow-600 text-black py-1 rounded-full">Pending</Badge>;
      case 'IN_TRANSIT':
        return <Badge color="orange" className="bg-orange-700 text-white py-1 rounded-full">In Transit</Badge>;
      case 'COMPLETED':
        return <Badge color="green" className="bg-green-700 text-white py-1 rounded-full">Completed</Badge>;
      case 'FAILED':
        return <Badge color="red" className="bg-red-700 text-white py-1 rounded-full">Failed</Badge>;
      default:
        return <Badge color="gray" className="bg-gray-700 text-white py-1 rounded-full">Unknown</Badge>;
    }
  };

  if (shippings.length === 0 ){
    return (
        <div className='flex justify-center w-full'>
          <p>No shippings yet</p>
        </div>
      )
  }

  if (fetchShippingsError === true) {
    return <p>Error fetching</p>;
  } else {
    return (
      <>
        {shippings.map((shipping) => (
          <Table.Row key={shipping.transaction_hash} className="text-textPrimary hover:bg-borderGray">
            <Table.Cell className="flex items-center space-x-2">
              <Tooltip content="Copy hash" placement="top">
                <span title={shipping.transaction_hash} className="font-mono text-sm text-textPrimary">
                  {truncateHash(shipping.transaction_hash)}
                </span>
              </Tooltip>
              <button
                onClick={() => handleCopyHash(shipping.transaction_hash)}
                title="Copy full hash"
                className="text-primary hover:text-primaryDark focus:outline-none p-1"
              >
                {copiedHash === shipping.transaction_hash ? (
                  <CheckCircleIcon className="w-4 h-4 text-green-500" />
                ) : (
                  <ClipboardDocumentIcon className="w-4 h-4" />
                )}
              </button>
            </Table.Cell>
            <Table.Cell>{getStatusBadge(shipping.status)}</Table.Cell>
            <Table.Cell>{shipping.date_created.toLocaleString()}</Table.Cell>
            <Table.Cell>
              <a
                href={shipping.blockLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-accentHover underline"
              >
                View Block
              </a>
            </Table.Cell>
            <Table.Cell>
              <Link to={`/shippings/${shipping.id}`} className="text-primary hover:text-primaryDark underline">
                View Details
              </Link>
            </Table.Cell>
          </Table.Row>
        ))
        }
      </>
    );
  }
  
}

export default ShipmentsTable;
