import { useConnect } from "@starknet-react/core";
import Recorder from "../shipping/pages/VoiceRecording";


export const CryptoConnect = () => {
  const { connect, connectors } = useConnect();

  const makeConnection = (connector) => {
    connect({ connector });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-background via-gray-800 to-background flex flex-col items-center justify-center px-4">
      <div className="bg-cardBg rounded-2xl shadow-2xl p-10 w-full max-w-md border border-borderGray">
        <h1 className="text-3xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-primary to-primaryDark">
          Connect Your Wallet
        </h1>
        <ul className="space-y-4">
          {connectors.map((connector) => (
            <li key={connector.id}>
              <div className="flex items-center justify-between bg-background p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 border border-borderGray">
                <img
                  src={connector.icon}
                  alt={`${connector.name} icon`}
                  className="w-10 h-10 object-contain"
                />
                <button
                  className={`py-2 px-6 rounded-lg font-semibold transition-all duration-300 flex-1 ml-4 text-sm
                    ${
                      connector.name === "argentX"
                        ? "bg-primary hover:bg-primaryDark text-textPrimary"
                        : "bg-accent hover:bg-primaryDark text-white"
                    }`}
                  onClick={() => makeConnection(connector)}
                >
                  Connect with {connector.name}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* <Recorder/> */}
    </div>
  );
};
