import React, { useState } from 'react';


const southeastAsianPorts = [
  "Port of Singapore, Singapore",
  "Port Klang, Malaysia",
  "Tanjung Priok, Indonesia",
  "Laem Chabang, Thailand",
  "Manila International Container Terminal, Philippines",
  "Port of Ho Chi Minh City (Saigon), Vietnam",
  "Cai Mep-Thi Vai, Vietnam",
  "Sihanoukville, Cambodia",
  "Thilawa Port, Myanmar",
  "Muara Port, Brunei"
];

const NewContainer = ({ onChange }) => {
  const [containerData, setContainerData] = useState({
    contents: '',
    departureLocation: '',
    arrivalLocation: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContainerData((prevData) => ({ ...prevData, [name]: value }));
    onChange({ ...containerData, [name]: value });
  };

  

  const validateField = (fieldName) => {
    if (!containerData[fieldName]) {
      console.log(containerData)
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: `${fieldName} is required` }));
    } else {
      setErrors((prevErrors) => {
        const { [fieldName]: _, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  // useEffect(() => {
  //   Object.keys(containerData).forEach((name) => {
  //     validateField(name);
  //   });
  // }, [containerData]);

  return (
    <div className="space-y-6">
      {['contents'].map((field, idx) => (
        <div key={idx}>
          <label htmlFor={field} className="block text-textPrimary mb-1">
            {`${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}`}
          </label>
          {field === 'contents' ? (
            <textarea
              id={field}
              name={field}
              value={containerData[field]}
              onChange={handleChange}
              placeholder={`Enter ${field}`}
              className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
              rows={3}
            />
          ) : (
            <input
              id={field}
              name={field}
              type="text"
              value={containerData[field]}
              onChange={handleChange}
              placeholder={`Enter ${field}`}
              className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
            />
          )}
          {errors[field] && <p className="text-error text-sm mt-1">{errors[field]}</p>}
        </div>
      ))}

      {['departureLocation', 'arrivalLocation'].map((field, idx) => (
        <div key={idx}>
          <label htmlFor={field} className="block text-textPrimary mb-1">
            {`${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}`}
          </label>
          <select
            id={field}
            name={field}
            value={containerData[field]}
            onChange={handleChange}
            className="w-full p-3 bg-cardBg text-textPrimary border border-borderGray rounded-md focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
          >
            <option value="">Select {field}</option>
            {southeastAsianPorts.map((port, index) => (
              <option key={index} value={port}>
                {port}
              </option>
            ))}
          </select>
          {errors[field] && <p className="text-error text-sm mt-1">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default NewContainer;
