// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './auth/Login';
import Navigation from './commons/Navigation';
import NewShippingPage from './shipping/pages/NewShipping';
import ShipmentsPage from './shipping/pages/Shipments';
import ShippingDetail from './shipping/pages/ShippingDetail';

import { useAccount } from "@starknet-react/core";


const App = () => {

  const { status, account, address } = useAccount();
  const [ sessionKey, setSessionKey ] = useState(null);
  
  return (
    <Router>
      <div className="min-h-screen bg-background text-textPrimary">
        {
          status == "connected" && sessionKey ?
          <>
            <Navigation setSessionKey={setSessionKey}/>
            <main className="max-w-7xl mx-auto">
              <Routes>
                <Route path="/" element={<ShipmentsPage />} />
                <Route path="/new-shipping" element={<NewShippingPage />} />
                <Route path="/shippings/:id" element={<ShippingDetail />} />
              </Routes>
            </main>
          </>
        :
        <Login sessionKey={sessionKey} setSessionKey={setSessionKey} account={account} address={address}/>
        }
      </div>
    </Router>
  );
};

export default App;
