import { RpcProvider, Contract, num, hash} from 'starknet';

import { parseShippingData, parseShippingCreatedEvent, parseShippingEvent } from '../../utils/parse';
import { intToU256Struct, u256toInt } from '../../utils/utils';
import { abi, deploymentData} from '../../artifacts';


export class ShippingService {

  constructor(){
    this.provider = new RpcProvider({ nodeUrl: deploymentData.nodeUrl });
    this.contract = new Contract(abi, deploymentData.contractAddress, this.provider);
    this.blockExplorerUrl = deploymentData.blockExplorerUrl;
  }

  // very ugly method
  // first call view function in contract
  // then fetch events to map the transaction hash
  async getAll() {
    try {
        let shippingData = await this.contract.call("get_shippings")
        let parsedShippingData = []
        for (let i = 0; i < shippingData.length; i++){
          parsedShippingData.push(
            parseShippingData(shippingData[i])
          )
        }
        const filter = {
          address: deploymentData.contractAddress,
          keys: [[num.toHex(hash.starknetKeccak("ShippingCreated"))]],
          fromBlock: 0,
          toBlock: 'latest',
          chunk_size: 100,
        };
        const result = await this.provider.getEvents(filter);
        const parsedEvents = result.events.map(event => parseShippingCreatedEvent(event));
        for (let i = 0; i<parsedShippingData.length; i++){
          parsedShippingData[i].transaction_hash = parsedEvents[i].transaction_hash
          parsedShippingData[i].blockLink = `${deploymentData.blockExplorerUrl}/blocks/${parsedEvents[i].block_number}` 
        }
        return parsedShippingData
      } catch (error) {
        console.error('Error fetching events:', error);
      }
  }

  async getAllShippingEvents(shippingId){
      const shippingEventKey = num.toHex(hash.starknetKeccak("ShippingEventCreated"));
      const { low, high } = intToU256Struct(shippingId)
      const filter = {
          address: deploymentData.contractAddress,
          keys: [
            [shippingEventKey], // Event name key
            [high, low]         // `shipping_id` `u256` key components
        ],
          fromBlock: 0,
          toBlock: 'latest',
          chunk_size: 100,
      };
      const result = await this.provider.getEvents(filter);
      const parsedEvents = result.events.map(event => parseShippingEvent(event, deploymentData));
      return parsedEvents
    }

    async create(sessionAccount, containerName, contents, shipper, receiver, origin, destination, timestamp){
      const txDetails = {
        contractAddress: this.contract.address,
        entrypoint: 'create_shipping',
        calldata: [containerName, contents, shipper, receiver, origin, destination, timestamp],
      };
      const response = await sessionAccount.execute(txDetails);
      await new Promise((resolve) => setTimeout(resolve, 250));
      const receipt = await this.provider.getTransactionReceipt(response.transaction_hash);
      const events = receipt.events;
      const shippingEventCreated = num.toHex(hash.starknetKeccak("ShippingCreated"))
      let createdShippingId;
      for (let i = 0; i < events.length; i++){
        if (events[i].keys[0] == shippingEventCreated){
          createdShippingId = u256toInt(events[i].keys[2], events[i].keys[1])
        }
      }
      return createdShippingId;
    }

    async createShippingEvent(sessionAccount, shippingId, description, timestamp){
      const txDetails = {
        contractAddress: this.contract.address,
        entrypoint: 'create_shipping_event',
        calldata: [intToU256Struct(shippingId), description, timestamp],
      };
      const response = await sessionAccount.execute(txDetails);
      await new Promise((resolve) => setTimeout(resolve, 250));
      return response
    }

    async validateReceipt(sessionAccount, shippingId, timestamp){
      const txDetails = {
        contractAddress: this.contract.address,
        entrypoint: 'validate_receipt',
        calldata: [intToU256Struct(shippingId), timestamp],
      };
      const response = await sessionAccount.execute(txDetails);
      await new Promise((resolve) => setTimeout(resolve, 250));
      return response
    }

    async getShippingCreatedEvent(shippingId){
      const { low, high } = intToU256Struct(shippingId)
      const filter = {
        address: deploymentData.contractAddress,
        keys: [
          [num.toHex(hash.starknetKeccak("ShippingCreated"))],
          [high, low]
        ],
        fromBlock: 0,
        toBlock: 'latest',
        chunk_size: 100,
      };
      const result = await this.provider.getEvents(filter);
      return result.events[0]
    }

    async retrieveShipping(shippingId){
      let shippingData = await this.contract.call("get_shipping", [shippingId])
      let parsedShipping = parseShippingData(shippingData);
      return parsedShipping
    }
}

export const shippingService = new ShippingService();